.worklist_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 2rem;
    /* max-width: 30rem; */
    /* background-color: aquamarine; */
}

.worklist_container a {
    text-decoration: none;
}

.workitem_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--main-white);

    margin-bottom: .5rem;
    padding: .75rem;
    border-radius: .5rem;
    width: 11rem;

    color: var(--main-bg);
    font-size: .8rem;

    border: 1px solid var(--main-white);
}

.worklist_text {
    color: var(--main-white);
    font-size: .5rem;

    margin-bottom: .5rem;
    margin-left: -1rem;
}

.workitem_container-history:hover {
    cursor: pointer;
    background-color: var(--main-primary-light);
    border: 1px solid var(--main-primary-light);
}

.workitem_container-history:active {
    color: var(--main-white);
    background-color: var(--main-black);
    border: 1px solid var(--main-primary-light)
}

.workitem_container-projects:hover {
    cursor: pointer;
    background-color: var(--main-primary-other);
    border: 1px solid var(--main-primary-other);
}

.workitem_container-projects:active {
    color: var(--main-white);
    background-color: var(--main-black);
    border: 1px solid var(--main-primary-other)
}


.workitem_container-olympics {
    background-color: var(--main-black);
    border: 1px solid var(--main-white);
}

.workitem_container-olympics:hover {
    background-color: var(--main-accent);
    border: 1px solid var(--main-accent);
}

.workitem_container-olympics-half {
    opacity: .25;
    color: var(--main-white);
}

.workitem_container-olympics-half:hover {
    opacity: 1;
    background-color: var(--main-green);
    border: 1px solid var(--main-green);
    color: var(--main-black);
}

.work-details {
    margin-bottom: 1rem;
}

.companyTag {
    font-size: 1.2rem;
    margin-bottom: .5rem;
    padding-left: 1rem;
}

.work-description {
    font-size: .8rem;
    margin-top: 1rem;
    padding-left: 1rem;
}

.link-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.work-link {
    color: var(--main-green);
    font-size: .8rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    /* text-decoration: underline; */
}

@media (max-width: 600px) {
    .workitem_container {
        padding: .5rem;
    }
}