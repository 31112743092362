@import 'hero-styles.css';
@import 'work-styles.css';


*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

:root {
    background: var(--main-bg);
  --main-bg: #020311;
  --main-primary: #230555;
  --main-primary-middle: #3D177D;
  --main-primary-light: #5700E4;
  --main-primary-light2: #813feb;
  --main-primary-other: #ff9e2f;
  --main-primary-other2: rgb(250, 185, 112);
  --main-white: #fff;
  --main-accent: #FF27C3;
  --main-grey: rgba(241, 241, 241, 0.825);
  --main-green: #5ef663;
  --main-red: rgb(255, 99, 99);
}

.App {
    background: var(--main-bg);
    margin: 0 auto;
    /* height: auto;
    min-height: 100vh; */

}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* min-height: 100vh; */
}

.darkbg-text {
    color: var(--main-white);
    max-width: 20rem;
}

.bio_box {
    max-width: 20rem;
    text-align: justify;
    /* width: 30rem; */
    /* background-color: #3D177D; */
}

.bio_text {
    color: var(--main-white);
    font-size: 1rem;
    margin-bottom: 1rem;

}

@media (max-width: 600px) {
    .App {
        max-height: 90vh;
    }

    .container {
        padding: 0 2rem;
        min-height: 90vh;
    }
}