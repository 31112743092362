.hero-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: aqua;
    background-color: var(--main-bg);
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
    padding-bottom: 2rem;
    /* margin-left: -2rem; */
    /* height: 100vh; */
}

.heroText {
    font-size: 4rem;
    color: var(--main-white);
    line-height: .4;
    margin-top: 2rem;
    /* margin-left: 1rem; */
}

@media (max-width: 600px) {
    .heroText {
        font-size: 2rem;
        margin-top: 1rem;
    }
}